import * as $ from 'jquery';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  CommonModule,
  LocationStrategy,
  HashLocationStrategy
} from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { FullComponent } from './layouts/full/full.component';
import { BlankComponent } from './layouts/blank/blank.component';

import { NavigationComponent } from './shared/header-navigation/navigation.component';
import { SidebarComponent } from './shared/sidebar/sidebar.component';
import { BreadcrumbComponent } from './shared/breadcrumb/breadcrumb.component';

import { Approutes } from './app-routing.module';
import { AppComponent } from './app.component';
import { SpinnerComponent } from './shared/spinner.component';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

//Servicios
import { HttpService } from './shared/services/http/http.service';
import { AuthGuard } from "./authentication/auth.guard";
import { AuthService } from "./authentication/auth.service";
import { AppService } from "./shared/services/app/app.service";
import { UsuarioService } from "./usuario/usuario.service";
import { TemplateService } from "./template/template.service";
import { BlacklistService } from "./blacklist/blacklist.service";
import { CompanyService } from "./company/company.service";
import { OfficeService } from "./office/office.service";
import { TagService } from "./review/tag.service";
import { AuthorizationService } from "./authorization/authorization.service";
import { NotificationService } from "./notification/notification.service";

//Modulos
import { SharedModule } from "./shared/shared.module";
import { Parameters } from "../../parameters";
import { ToastrModule } from "ngx-toastr";
import { AngularFireDatabaseModule } from "@angular/fire/database";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFireMessagingModule } from "@angular/fire/messaging";
import { AngularFireModule } from "@angular/fire";
import { environment } from "../environments/environment";
import { MessagingService } from "./shared/messaging/messaging.service";
import { FcmService } from "./fcm/fcm.service";
import { JoyrideModule } from "ngx-joyride";
import { AuthInterceptorService } from "./shared/interceptor/auth-interceptor.service";
import { UtilService } from "./shared/services/app/util.service";

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 1,
  wheelPropagation: true,
  minScrollbarLength: 20
};

@NgModule({
  declarations: [
    AppComponent,
    SpinnerComponent,
    FullComponent,
    BlankComponent,
    NavigationComponent,
    BreadcrumbComponent,
    SidebarComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgbModule,
    RouterModule.forRoot(Approutes, { useHash: false, relativeLinkResolution: 'legacy' }),
    PerfectScrollbarModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    ToastrModule.forRoot(),
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase),
    JoyrideModule.forRoot(),
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    // {
    //   provide: LocationStrategy,
    //   useClass: HashLocationStrategy
    // },
    {
      provide: 'Parameters',
      useClass: Parameters
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true
    },
    HttpService,
    HttpClient,
    AuthGuard,
    AuthService,
    AppService,
    UsuarioService,
    TemplateService,
    BlacklistService,
    CompanyService,
    OfficeService,
    TagService,
    AuthorizationService,
    NotificationService,
    MessagingService,
    FcmService,
    UtilService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
