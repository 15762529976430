import { Injectable } from '@angular/core';
import { ApiService } from '../shared/services/api/api.service';
import { HttpService } from '../shared/services/http/http.service';
import { Observable } from 'rxjs';

@Injectable()
export class UsuarioService extends ApiService {

    constructor(protected http: HttpService) {
        super(http);
        this.url = 'api/users';
    }

    getProfile(): Observable<any> {
        return this.http.get(`${this.url}/profile`);
    }

    updateProfile(user: any): Observable<any> {
        let resource = super.serialize(user);
        return this.http.put(`${this.url}/profile`, resource);
    }

    updatePassword(password: any): Observable<any> {
        let resource = super.serialize(password);
        return this.http.put(`${this.url}/profile/password`, resource);
    }

    updatePasswordByUser(id, password: any): Observable<any> {
        let resource = super.serialize(password);
        return this.http.put(`${this.url}/${id}/password`, resource);
    }

    changeStatusNotification(name, value) {

        let params = {
            notification: name,
            allow: value
        };

        return this.http.put(`${this.url}/notification`, super.serialize(params));
    }

    resetPassword(email) {
        return this.http.get(`${this.url}/reset/asks`, super.serialize(email), true);
    }

    resetForgotPassword(params) {
        return this.http.put(`${this.url}/reset`, super.serialize(params));
    }

    getBadges(): Observable<any> {
        return this.http.get(`${this.url}/profile/badges`);
    }

    availableUser(params): Observable<any> {
        return this.http.get(`${this.url}/available`, params, true);
    }

    changeStatusFeature(feature_slug: string) {

        let params = {
            feature_slug: feature_slug
        };

        return this.http.put(`${this.url}/profile/feature`, super.serialize(params));
    }

    getFeatures(): Observable<any> {
        return this.http.get(`${this.url}/profile/features`);
    }

    uploadFile(file: any): Observable<any> {
        return this.http.post(`${this.url}/upload`, file);
    }

    addCompanyAccount(id, company_id): Observable<any> {
        let params = {
            company_id: company_id
        };
        return this.http.post(`${this.url}/account/${id}`, params);
    }

    deleteCompanyAccount(id, company_id): Observable<any> {
        let params = {
            company_id: company_id
        };
        return this.http.deleteWithBody(`${this.url}/account/${id}`, params);
    }

    getAdmins(params?: any): Observable<any> {
        if (!params)
            return this.http.get(this.url);
        params = this.serialize(params);
        return this.http.get(this.url, params, false, true);
    }
}
